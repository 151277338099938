<template>
  <component
    :class="iconClass"
    :key="`${iconStyle}-${iconName}`"
    :is="icon"
    alt="test" />
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent, computed } from 'vue';

export const PROGRESS_LENGTH = 1362;

export function calculateProgress (percent: number): number {
  return Math.min(Math.max(-PROGRESS_LENGTH + (percent * PROGRESS_LENGTH), -PROGRESS_LENGTH), 0);
}

export default defineComponent({
  props: {
    iconStyle: {
      type: String,
      default: 'regular',
      required: false,
      validator (value: string) {
        return Object.values(['regular', 'solid', 'light', 'duotone', 'brand']).includes(value);
      },
    },
    iconName: {
      type: String,
      required: false,
    },
    marginRight: {
      type: Boolean,
      required: false,
    },
    loadingIcon: {
      type: Boolean,
      required: false,
    },
  },
  setup (props: any) {
    const iconClass = computed(() => ({
      'jwl-icon--margin-right': props.marginRight,
    }));

    let { iconName, iconStyle } = props;
    if (props.loadingIcon) {
      iconName = 'spinner-third';
      iconStyle = 'duotone';
    }

    const icon = defineAsyncComponent(() => import(`../assets/fa-icons/${iconStyle}/${iconName}.svg`));

    return {
      iconClass,
      icon,
    };
  },
});
</script>

<style lang="scss">
.jwl-icon {
  &--margin-right {
    margin-right: .5rem;
  }
}
</style>
