import Vue from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import JwlCourseDocument from '@/components/jwlCourseDocument.vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import 'virtual:uno.css';

Vue.use(CKEditor);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// Languages supported by this SPA
const languages = ['en', 'es', 'ar'];
// Languages that have an rtl writing direction
const rtlLanguages = ['ar'];

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  if (!to.params.lang || !languages.includes(to.params.lang)) {
    const lang = window.navigator.language.slice(0, 2);
    const param = languages.includes(lang)
      ? lang
      : 'en';
    const isRtl = rtlLanguages.includes(lang);
    if (isRtl) {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }
    i18n.locale = param;
    // learningStore.SET_LOCALE(param);

    next({ name: 'home', params: { lang: param } });
  } else if (from.params.lang !== to.params.lang) {
    const { lang } = to.params;
    document.documentElement.lang = lang;
    const isRtl = rtlLanguages.includes(lang);

    i18n.locale = lang;
    // learningStore.SET_LOCALE(lang);

    if (isRtl) {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }

    next();
  } else {
    next();
  }
});

Vue.component('jwl-course-document', JwlCourseDocument);

const VueApp = new Vue({
  el: '#app',
  router,
  i18n,
  pinia,
  components: { App },
  template: '<App/>',
});
