import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n-composable';

import countries from '@base/../node_modules/i18n-iso-countries/langs/en.json';
import countriesEs from '@base/../node_modules/i18n-iso-countries/langs/es.json';

import commonEn from '@base/locales/en.json';
import commonEs from '@base/locales/es.json';
import en from './locales/en.json';
import ar from './locales/ar.json';
import es from './locales/es.json';

Vue.use(VueI18n);

export const defaultLocale = 'en';

export const languages = {
  en,
  ar,
  es,
};

export const commonLanguages = {
  en: commonEn,
  es: commonEs,
};

function loadLocaleMessages () {
  const locales = [{ en: languages.en }, { es: languages.es }, { ar: languages.ar }];
  const commonLocales = [{ en: commonLanguages.en }, { es: commonLanguages.es }];
  const messages = {};
  locales.forEach((lang) => {
    const key = Object.keys(lang);
    messages[key] = lang[key];
  });

  commonLocales.forEach((lang) => {
    const key = Object.keys(lang);
    Object.assign(messages[key], lang[key]);
  });

  messages.en = Object.assign(messages.en, countries);
  messages.es = Object.assign(messages.es, countriesEs);

  return messages;
}

/**
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @returns a final choice index to select plural word by
 */
function pluralize (choice: number): number {
  if (choice === 1) {
    return 1;
  }

  if (choice < 1) {
    return 0;
  }

  return 2;
}

export default createI18n({
  locale: import.meta.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  pluralizationRules: {
    en: pluralize,
    es: pluralize,
  },
});
