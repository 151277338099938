<template>
  <a
    v-if="isLink"
    :href="linkUrl"
    class="jwl-button"
    :class="buttonClass">
    <jwl-icon v-if="hasIcon"
              :key="`${iconStyle}-${iconName}`"
              :icon-name="iconName"
              :icon-style="iconStyle"
              class="jwl-button__icon" />
    <slot />
  </a>
  <router-link
    v-else-if="isRouterLink"
    class="jwl-button"
    :class="buttonClass"
    :to="toRoute">
    <jwl-icon v-if="hasIcon"
              :key="`${iconStyle}-${iconName}`"
              :icon-name="iconName"
              :icon-style="iconStyle"
              class="jwl-button__icon" />
    <slot />
  </router-link>
  <button
    v-else
    :disabled="buttonDisabled"
    class="jwl-button"
    :class="buttonClass"
    @click.prevent="emitClick">
    <jwl-icon v-if="hasIcon"
              :key="`${iconStyle}-${iconName}`"
              :icon-name="iconName"
              :icon-style="iconStyle"
              class="jwl-button__icon" />
    <slot />
  </button>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import JwlIcon from '@base/components/JwlIcon.vue';

@Component({
  components: {
    JwlIcon,
  },
})
export default class JwlButton extends Vue {
  @Prop(Object)
  toRoute!: Route | undefined;

  @Prop(String)
  linkUrl!: string;

  @Prop({ default: 'regular' })
  iconStyle!: string;

  @Prop(String)
  iconName!: string | null;

  @Prop(Boolean)
  iconAtEnd!: boolean;

  @Prop(Boolean)
  buttonBordered!: boolean;

  @Prop(Boolean)
  buttonTab!: boolean;

  @Prop(Boolean)
  buttonArea!: boolean;

  @Prop(Boolean)
  buttonSmall!: boolean;

  @Prop(Boolean)
  buttonLarge!: boolean;

  @Prop(Boolean)
  buttonDisabled!: boolean;

  @Prop(Boolean)
  buttonActive!: boolean;

  @Prop(Boolean)
  marginLeft!: boolean;

  @Prop(Boolean)
  marginRight!: boolean;

  @Prop(Boolean)
  marginAuto!: boolean;

  @Prop(Boolean)
  fullWidth!: boolean;

  @Prop(Boolean)
  normalButtonStyle!: boolean;

  emitClick (): void {
    this.$emit('button-clicked');
  }

  get buttonClass (): Record<string, boolean> {
    return {
      'jwl-button--bordered': this.buttonBordered,
      'jwl-button--has-icon': this.hasIcon,
      'jwl-button--icon-at-end': this.iconAtEnd,
      'jwl-button--small': this.buttonSmall,
      'jwl-button--large': this.buttonLarge,
      'jwl-button--tab': this.buttonTab,
      'jwl-button--area': this.buttonArea,
      'jwl-button--margin-left': this.marginLeft,
      'jwl-button--margin-right': this.marginRight,
      'jwl-button--margin-auto': this.marginAuto,
      'jwl-button--disabled': this.buttonDisabled,
      'jwl-button--active': this.buttonActive,
      'jwl-button--full-width': this.fullWidth,
      'jwl-button--normal': this.normalButtonStyle,
    };
  }

  get isLink (): boolean {
    return !!this.linkUrl && !this.buttonDisabled;
  }

  get isRouterLink (): boolean {
    return !!this.toRoute && !this.buttonDisabled;
  }

  get hasIcon (): boolean {
    return !!this.iconName;
  }
}
</script>

<style lang="scss">
.jwl-button {
  $root: &;

  --border-color: var(--button-color, var(--concentration-color, var(--page-color, var(--color-blue))));
  --primary-color: var(--button-color, var(--concentration-color, var(--page-color, var(--color-blue))));
  --inverse-color: var(--button-inverse, var(--color-white));

  align-items: center;
  background-color: var(--primary-color);
  border-radius: 1.5em;
  border: 2px solid var(--border-color);
  color: var(--inverse-color);
  cursor: pointer;
  display: flex;
  font-weight: var(--font-weight-normal);
  font-size: 1rem;
  line-height: 1.1em;
  padding: 0.5rem 2rem;
  text-decoration: none;
  transition: background-color .25s, color .25s;
  width: max-content;

  &:hover {
    background-color: var(--inverse-color);
    color: var(--primary-color);
  }

  &--active:not(&--tab),
  &.router-link-active:not(&--tab, &--normal) {
    --inverse-color: var(--button-color, var(--concentration-color, var(--page-color, var(--color-blue))));
    --primary-color: var(--button-inverse, var(--color-white));
  }

  &:disabled,
  &--disabled {
    --primary-color: var(--color-gray-500);
    --border-color: var(--color-gray-500);

    cursor: not-allowed;
  }

  &--icon-at-end {
    flex-flow: row-reverse;
  }

  &--bordered {
    background-color: var(--inverse-color);
    color: var(--primary-color);

    &:hover {
      background-color: var(--primary-color);
      color: var(--inverse-color);
    }
  }

  &--small {
    font-size: .85rem;
    padding: .2em 1em;
  }

  &--large {
    font-size: 1em;
    padding: 1.25rem 1rem;
  }

  &--tab {
    background-color: var(--color-gray-100);
    border: none;
    border-radius: var(--border-radius);
    color: var(--color-gray-800);
    font-size: 1.15rem;
    justify-content: flex-start;
    min-height: 2.5rem;
    min-width: 3.5rem;
    padding: .3rem .75rem;
    position: relative;
    text-align: left;
    width: 100%;

    &::after {
      background-color: var(--primary-color);
      border-radius: var(--border-radius);
      bottom: 0;
      content: "";
      height: .25rem;
      left: 0;
      opacity: 0;
      position: absolute;
      transition: opacity .25s;
      width: 100%;
    }

    &:hover {
      background-color: var(--color-gray-100);
      color: var(--primary-color);
    }

    &#{$root}--active,
    &.router-link-active {
      background-color: var(--color-white);
      border-radius: 0;
      color: var(--primary-color);

      &::after {
        opacity: 1;
      }
    }

    &:hover::after {
      opacity: .5;
    }

    &#{$root}--small {
      font-size: 1rem;
      padding: .5rem 1rem;
    }
  }

  &--area {
    background-color: var(--color-gray-100);
    border: none;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    color: var(--color-gray-800);
    font-size: 1.15rem;
    justify-content: flex-start;
    min-height: 2.5rem;
    min-width: 3.5rem;
    padding: 1rem 1.5rem;
    position: relative;
    text-align: left;
    width: 100%;

    &:hover {
      background-color: var(--color-gray-100);
      color: var(--primary-color);
    }

    &#{$root}--active,
    &.router-link-active {
      background-color: var(--inverse-color);
      color: var(--primary-color);
    }

    &#{$root}--small {
      padding: .6rem 1.5rem;
    }
  }

  &--margin-left {
    margin-left: .5rem;
  }

  &--margin-right {
    &:not(:last-child) {
      margin-right: .5rem;
    }
  }

  &--margin-auto {
    margin-left: auto;
    margin-right: auto;
  }

  &--full-width {
    box-sizing: border-box;
    justify-content: center;
    width: 100%;
  }

  &__icon {
    flex: 0 0 1em;
    height: 1em;
    width: 1em;

    #{$root}--icon-at-end & {
      margin-left: .5rem;
    }

    #{$root}:not(#{$root}--icon-at-end) & {
      margin-right: .5rem;
    }
  }
}
</style>
