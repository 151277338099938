import languages from 'language-subtag-registry/data/json/registry.json';
import { SelectOption } from './FormElementOptions';

interface LanguageSubtag {
  Type: string,
  Subtag: string,
  'Description': string[]
  Added: string,
  'Suppress-Script': string,
  Scope: string
}

const LANGUAGES_LIST: SelectOption[] = [];

languages.forEach((language: LanguageSubtag) => {
  if (language.Type === 'language') {
    LANGUAGES_LIST.push({
      id: language.Subtag,
      label: language.Description.join(', '),
    });
  }
});

export default LANGUAGES_LIST;
