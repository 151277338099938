<template>
  <form class="sis__container container"
        ref="submitForm"
  >
    <div class="userDataValidationOverlay fade-in" v-show="showUserDataValidationOverlay">
      <div class="userDataValidationOverlayContainer" ref="scrollContainer">
        <div class="userDataValidationOverlayRow">
          {{ $t('userDataValidation.infoTop') }}
          <br>
          <div class="userDataValidationOverlayColumnLeft">
            <div>{{ $t('formElement.first-name') }}:</div>
          </div>
          <div v-html="currentUser.firstName" class="userDataValidationOverlayColumnRight"></div>

          <div class="userDataValidationOverlayColumnLeft">
            <div>{{ $t('formElement.middle-name') }}:</div>
          </div>
          <div v-html="currentUser.middleName" class="userDataValidationOverlayColumnRight"></div>

          <div class="userDataValidationOverlayColumnLeft">
            <div>{{ $t('formElement.last-name') }}:</div>
          </div>
          <div v-html="currentUser.lastName" class="userDataValidationOverlayColumnRight"></div>

          <div class="userDataValidationOverlayColumnLeft">
            <div>{{ $t('formElement.birth-date') }}:</div>
          </div>
          <div v-html="currentUser.birthDate"
               class="userDataValidationOverlayColumnRight"></div>

          <div class="userDataValidationOverlayColumnLeft">
            <div>{{ $t('formElement.email') }}:</div>
          </div>
          <div v-html="currentUser.eMail" class="userDataValidationOverlayColumnRight"></div>
        </div>
        <div class="userDataValidationOverlayRow">
          {{ $t('userDataValidation.infoMid') }}
        </div>
        <div class="userDataValidationOverlayRow">

          <form-element field-name="phone"
                        field-type="phone"
                        :required="true"
                        :value="currentUser.phoneNumber"
                        @change="currentUser.phoneNumber = $event"
          />
        </div>
        <div v-if="currentUser.residencyStatus !== null" class="userDataValidationOverlayRow">
          <form-element :field-name="studentInformation.residencyStatus.fieldName"
                        :field-type="studentInformation.residencyStatus.fieldType"
                        :required="studentInformation.residencyStatus.required"
                        :field-options="studentInformation.residencyStatus.selectOptions ? studentInformation.residencyStatus.selectOptions: []"
                        :field-options-translate="true"
                        :value="currentUser.residencyStatus"
                        @change="currentUser.residencyStatus = $event"/>
        </div>

        <div class="userDataValidationOverlayRow">
          <p v-html="$t('userDataValidation.infoBottom')"/>
        </div>
        <div class="userDataValidationOverlayRow" style="align: right">
          <jwl-button name="Accepted" :buttonDisabled="formattedPhone"
                      @button-clicked="acceptUserData">{{ $t('userDataValidation.accept-and-save') }}
          </jwl-button>
          <jwl-button class="jwl-button red" name="rememberMe" @button-clicked="goOn">{{ $t('userDataValidation.remind-me-next-time') }}

          </jwl-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import JwlIcon from '@base/components/JwlIcon.vue';
import { mapState, mapStores } from 'pinia';
import User from '@base/store/User';
import JwlLanguageSwitcher from '@base/components/JwlLanguageSwitcher.vue';
import useModuleStore from '@base/store/moduleStore';
import { Watch } from 'vue-property-decorator';
import FormElement from '@base/components/FormElement.vue';
import FormElementOptions from '@base/helper/FormElementOptions';
import {
  RESIDENCY_STATUS,
} from '@base/store/fieldOptions';
import useLearningStore from '@/store/learningStore';
import JwlButton from '@/components/JwlButton.vue';

@Component({
  components: {
    JwlLanguageSwitcher,
    JwlButton,
    JwlIcon,
    FormElement,
  },
  computed: {
    ...mapStores(useModuleStore, useLearningStore),
    ...mapState(useModuleStore, [
      'currentUser',
    ]),
  },
})
export default class JwlUserDataValidationOverlay extends Vue {
  $refs!: {
    submitForm: HTMLFormElement;
    enabledFormField: any;
  };

  moduleStore!: any;
  learningStore!: any;
  currentUser!: User;
  loadingState = 0;
  showUserDataValidationOverlay = true;
  acceptAndSaveDisabled = false;

  residencyStatusSelect: any | null = null;
  studentInformation = {
    residencyStatus: new FormElementOptions('residency-status', 'select', false, RESIDENCY_STATUS),
  };

  acceptUserData (): void {
    this.loadingState = 1;
    const { submitForm } = this.$refs;

    if (submitForm.reportValidity()) {
      const formData = new FormData(submitForm);
      formData.append('formattedPhone', this.currentUser.phoneNumber);

      this.learningStore.postData({
        url: 'profile/update-user-data',
        formData,
      }).then((data) => {
        if (data.success === true) {
          this.currentUser.userDataValidated = data.userDataValidated;
        }
      }).catch(() => {
      });
    }
  }

  goOn (): void {
    this.showUserDataValidationOverlay = false;
    this.currentUser.userDataValidated = true;
  }

  get formattedPhone (): boolean {
    return !this.currentUser.phoneNumber?.startsWith('+');
  }
}
</script>

<style lang="scss">
@use "@base/styles/variables";
@use "@base/styles/mixins";

.userDataValidationOverlay {
  align-items: flex-start;
  backdrop-filter: blur(4px);
  background-color: transparentize(variables.$black, .2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: opacity .25s;
  width: 100%;
  z-index: 1010;

  &.fade-in {
    opacity: 1;
  }

  .userDataValidationOverlayContainer {
    background-color: variables.$white;
    border-radius: variables.$border-radius;
    box-sizing: border-box;
    margin: 10vh auto;
    max-width: variables.$container-width * 0.6;
    padding: 1.5rem 2rem;
    width: 90%;

    .userDataValidationOverlayColumnLeft {
      float: left;
      width: 20%;
    }

    .userDataValidationOverlayColumnRight {
      float: left;
      width: 75%;
    }

    .userDataValidationOverlayRow {
      margin-top: 1.5rem;
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      grid-gap: 0.5rem;

      .jwl-button.red {
        --button-color: var(--color-error);
      }
    }
  }
}

</style>
