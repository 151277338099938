<template>
  <ul class="jwl-course-document__root-list" v-if="Array.isArray(jwlFile)">
    <jwl-course-document
      v-for="file in jwlFile"
      :key="file.id"
      :jwl-file="file"
      :delete-url="deleteUrl"
      :student="student"
      @on-delete="$emit('on-delete', $event)" />
    <li v-if="Array.isArray(jwlFile) && jwlFile.length > 0">
      <div class="jwl-course-document__containing-hint">
        {{$t('course.virusHint')}}
      </div>
    </li>
  </ul>
  <li v-else-if="jwlFile">
    <template v-if="jwlFile.type === 'folder'">
      <div class="jwl-course-document jwl-course-document--folder" @click="toggleOpen">
        <div class="jwl-course-document__icon">
          <jwl-icon v-show="open" icon-name="folder-open" icon-style="duotone" />
          <jwl-icon v-show="!open" icon-name="folder" icon-style="duotone" />
        </div>

        <div class="jwl-course-document__text">
          <div class="jwl-course-document__title">
            {{jwlFile.name}}
          </div>
        </div>

        <jwl-toggle :is-open="open" />
      </div>

      <template v-if="open">
        <jwl-course-document
          class="jwl-course-document__file-list"
          :jwl-file="jwlFile.children"
          :delete-url="deleteUrl"
          :student="student"
          @on-delete="$emit('on-delete', $event)" />
      </template>
    </template>

    <a v-else class="jwl-course-document jwl-course-document--file" :href="jwlFile.url" download target="_blank">
      <div class="jwl-course-document__icon">
        <jwl-icon :icon-name="getIcon(jwlFile)" icon-style="duotone" />
      </div>

      <div class="jwl-course-document__text">
        <div class="jwl-course-document__title">
          {{jwlFile.name}}
        </div>
        <div class="jwl-course-document__file-size">
          {{jwlFile.size}}
        </div>
      </div>

      <button
        v-if="deleteUrl && jwlFile.deletable"
        class="jwl-course-document__delete"
        @click.prevent="deleteDocument">
        <jwl-icon icon-name="trash-alt" icon-style="solid" v-show="submitStatus === 0" />
        <jwl-icon icon-name="spinner-third" icon-style="duotone" v-show="submitStatus === 1" />
        <jwl-icon icon-name="check" icon-style="regular" v-show="submitStatus === 2" />
        <jwl-icon icon-name="exclamation-triangle" icon-style="duotone" v-show="submitStatus === -1" />
      </button>

      <div class="jwl-course-document__icon" v-else>
        <jwl-icon icon-style="solid" icon-name="download" />
      </div>
    </a>
  </li>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import JwlIcon from '@base/components/JwlIcon.vue';
import { Documents, Document } from '@base/store/interface/Document';
import { mapStores } from 'pinia';
import useLearningStore from '@/store/learningStore';
import JwlToggle from './jwlToggle.vue';

@Component({
  components: {
    JwlIcon,
    JwlToggle,
  },
  computed: mapStores(useLearningStore),
})
export default class JwlCourseDocument extends Vue {
  @Prop([Object, Array])
  jwlFile!: Documents | Document;

  @Prop(String)
  deleteUrl!: string;

  @Prop(String)
  student!: string;

  learningStore!: any;

  open = false;
  submitStatus = 0;

  deleteDocument (): void {
    if (this.submitStatus <= 1 && !Array.isArray(this.jwlFile)) {
      this.submitStatus = 1;
      let requestUrl = `${this.deleteUrl}/${this.jwlFile.id}`;
      if (this.student) {
        requestUrl += `/${this.student}`;
      }
      this.learningStore.deleteData(requestUrl)
        .then((data) => {
          this.submitStatus = 2;
          setTimeout(() => {
            this.$emit('on-delete', data);
          }, 1500);
        })
        .catch(() => {
          this.submitStatus = -1;
        });
    }
  }

  toggleOpen (): void {
    this.open = !this.open;
  }

  getIcon (file: Document): string {
    switch (file.mimeType) {
      case 'application/pdf':
        return 'file-pdf';
      case 'text/plain':
        return 'file-alt';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.oasis.opendocument.text':
        return 'file-word';
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.oasis.opendocument.spreadsheet':
        return 'file-excel';
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.oasis.opendocument.presentation':
        return 'file-powerpoint';
      case 'video/mpeg':
      case 'video/mp4':
      case 'video/ogg':
      case 'video/mp2t':
      case 'video/x-msvideo':
      case 'video/3gpp':
      case 'video/3gpp2':
        return 'file-video';
      case 'audio/aac':
      case 'audio/midi':
      case 'audio/x-midi':
      case 'audio/mpeg':
      case 'audio/ogg':
      case 'audio/opus':
      case 'audio/wav':
      case 'audio/webm':
      case 'audio/3gpp':
      case 'audio/3gpp2':
        return 'file-audio';
      case 'image/gif':
      case 'image/bmp':
      case 'image/jpeg':
      case 'image/png':
      case 'image/svg+xml':
      case 'image/webp':
        return 'file-image';
      case 'application/zip':
      case 'application/x-7z-compressed':
        return 'file-archive';
      case 'text/x-java':
        return 'file-code';
      default:
        return 'file';
    }
  }
}
</script>

<style lang="scss">
@use "@base/styles/mixins";
@use "@base/styles/variables";

.jwl-course-document {
  $root: &;

  --padding-x: .75rem;
  --padding-y: 1rem;
  --toggle-color: var(--concentration-color, var(--page-color));
  --icon-color: var(--concentration-color, var(--page-color));
  --text-color: var(--color-gray-800);
  --box-shadow: var(--document-box-color, var(--color-gray-400));

  align-items: center;
  border-radius: var(--border-radius);
  box-shadow: 0 0 .2rem var(--box-shadow);
  box-sizing: border-box;
  color: var(--text-color);
  display: flex;
  justify-content: flex-start;
  margin-bottom: .75rem;
  position: relative;
  text-decoration: none;
  transition: color .25s;
  width: 100%;

  @include mixins.mq(variables.$mq-laptop) {
    --padding-x: 1rem;
    --padding-y: .75rem;
  }

  @include mixins.mq(variables.$mq-phone) {
    --padding-x: 1rem;
    --padding-y: .5rem;
  }

  &:not(&--file) {
    padding: var(--padding-y) var(--padding-x);
  }

  &--file,
  &--folder {
    display: grid;
    grid-template: "icon text downloadIcon" auto / auto 1fr auto;
  }

  &:hover {
    --icon-color: var(--color-blue);
    --text-color: var(--color-blue);
  }

  &__root-list {
    list-style: none;
    padding: 0 0;

    #{$root}__root-list {
      @include mixins.ltrtl(margin-left, margin-right, 3rem);
    }
  }

  &--folder {
    cursor: pointer;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  &__text {
    align-items: flex-start;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 0 1rem;
    padding: var(--padding-y) 0;
    word-wrap: anywhere;
  }

  &__icon {
    align-items: center;
    color: var(--icon-color);
    display: flex;
    font-size: 2.25rem;
    padding: var(--padding-y) 0 var(--padding-y) var(--padding-x);
    transition: color .25s;

    &:last-child {
      @include mixins.ltrtl(margin-left, margin-right, auto);

      font-size: 2rem;
      padding: var(--padding-y) var(--padding-x) var(--padding-y) 0;

      @include mixins.mq(variables.$mq-phone) {
        display: none;
      }
    }
  }

  &__delete {
    align-items: center;
    background-color: transparent;
    border: none;
    border-left: .1rem solid var(--color-gray-300);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    color: var(--color-error);
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    height: 100%;
    padding: var(--padding-y) var(--padding-x) var(--padding-y) var(--padding-x);
    transition: color .25s, background-color .25s;

    &:hover {
      background-color: var(--color-error);
      color: var(--color-white);
    }
  }

  &__title {
    font-weight: variables.$font-weight-normal;
  }

  &__file-size {
    color: var(--document-hint-color, var(--color-gray-500));
    font-size: .75rem;
  }

  &__file-list {
    list-style: none;
  }

  &__containing-hint {
    align-items: center;
    background-color: var(--concentration-color);
    border-radius: var(--border-radius);
    color: var(--color-white);
    font-size: .85rem;
    padding: .25rem .5rem;
    margin-bottom: 1rem;
  }
}
</style>
