import { defineStore } from 'pinia';
import { api, handleErrorResponse } from '@base/store/moduleStore';

export const apiStatus = {
  STATUS_ERROR: 'error',
  STATUS_IDLE: 'idle',
  STATUS_FETCHING: 'fetching',
  STATUS_SUCCESS: 'success',
};

export const useLearningStore = defineStore('learning', {
  state: () => ({
    fetchingData: 0,
    clcs: [],
    clc: {},
    coordinators: [],
    courses: [],
    facilitators: [],
    programmes: [],
    programme: {},
    students: [],
    users: [],
    user: null,
    locale: 'en',
    concentration: null,
    discussion: null,
    currentTask: null,
    currentStudentId: null,
    lastOpenUnit: null,
    coursePreviews: [],
    errors: [],
  }),
  getters: {
    api: () => api,
  },
  actions: {
    SET_CONCENTRATION (data) {
      this.concentration = data;
    },
    SET_LOCALE (locale) {
      this.locale = locale;
    },
    SET_COURSE_PREVIEWS (data) {
      this.coursePreviews = data;
    },
    SET_DISCUSSION (data) {
      this.discussion = data;
    },
    ADD_COMMENT (data) {
      this.discussion?.posts.push(data);
    },
    HANDLE_LOGIN (data) {
      api.login(data.token, data.refreshToken);
    },
    SET_CURRENT_TASK (data) {
      this.currentTask = data;
    },
    SET_CURRENT_STUDENT_ID (data) {
      this.currentStudentId = data;
    },
    SET_LAST_OPEN_UNIT (data) {
      this.lastOpenUnit = data;
    },
    getData (url) {
      return new Promise((resolve, reject) => {
        api.axios.get(`/api/${this.locale}/${url}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            // this.REPORT_ERROR(e);
            reject(handleErrorResponse(e));
          });
      });
    },
    postData ({ url, formData }) {
      return new Promise((resolve, reject) => {
        api.axios({
          method: 'post',
          url: `/api/${this.locale}/${url}`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((response) => {
          resolve(response.data);
        }).catch((e) => {
          // this.REPORT_ERROR(e);
          reject(handleErrorResponse(e));
        });
      });
    },
    REPORT_ERROR (payload) {
      const newError = {
        error: payload,
        route: window.location.toString(),
        timestamp: new Date(),
      };
      this.errors.push(newError);
    },
    postEmptyData (url) {
      return new Promise((resolve, reject) => {
        api.axios.post(`/api/${this.locale}/${url}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            this.REPORT_ERROR(e);
            reject(handleErrorResponse(e));
          });
      });
    },
    deleteData (url) {
      return new Promise((resolve, reject) => {
        api.axios.delete(`/api/${this.locale}/${url}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            this.REPORT_ERROR(e);
            reject(handleErrorResponse(e));
          });
      });
    },
    commitWbt ({
      unitCode,
      wbtIndex,
      aiccData,
      additional,
    }) {
      return new Promise((resolve, reject) => {
        const additionalUrl = additional ? `/${additional}` : '';
        const postUrl = `/api/${this.locale}/wbt/${unitCode}/${wbtIndex}${additionalUrl}`;
        api.axios.post(postUrl, aiccData)
          .then(() => {
            resolve();
          })
          .catch((e) => {
            this.REPORT_ERROR(e);
            reject(handleErrorResponse(e));
          });
      });
    },
    publishGrade ({ concentrationCode, unitCode, jwlId }) {
      return new Promise((resolve, reject) => {
        api.axios.post(`/api/${this.locale}/gradebook/${concentrationCode}/publish/${unitCode}/${jwlId}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            this.REPORT_ERROR(e);
            reject(handleErrorResponse(e));
          });
      });
    },
    getCoursePreviews () {
      return new Promise((resolve, reject) => {
        api.axios.get(`/api/${this.locale}/preview/courses`)
          .then((response) => {
            this.SET_COURSE_PREVIEWS(response.data.sort((a, b) => (a.code > b.code ? 1 : -1)));
            resolve();
          })
          .catch((e) => {
            this.REPORT_ERROR(e);
            reject(handleErrorResponse(e));
          });
      });
    },
    publishTask ({ taskCode, onBehalf }) {
      let url = `/api/${this.locale}/task/${taskCode}/publish`;
      if (onBehalf != null) {
        url += `/${onBehalf}`;
      }
      return new Promise((resolve, reject) => {
        api.axios.post(url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            this.REPORT_ERROR(e);
            reject(handleErrorResponse(e));
          });
      });
    },
    submitData ({ url, submitData }) {
      return new Promise((resolve, reject) => {
        api.axios({
          method: 'post',
          url: `/api/${this.locale}/${url}`,
          data: submitData,
        }).then((response) => {
          resolve(response.data);
        }).catch((e) => {
          reject(handleErrorResponse(e));
        });
      });
    },
  },
});

export default useLearningStore;
