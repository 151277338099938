<template>
  <ul class="jwl-menu-overlay" v-if="menu.length">
    <li
      class="jwl-menu-overlay__list-item"
      v-for="sublist in menu"
      :key="`menu-${baseMenuName}-${sublist.modifier}`"
      :class="submenuListItemClass(sublist.modifier)">
      <div class="jwl-menu-overlay__list-item-label">
        {{$root.$t(`menu.${sublist.translatable}`)}}
      </div>

      <ul class="jwl-menu-overlay__sublist">
        <li
          class="jwl-menu-overlay__sublist-item"
          v-for="submenu in sublist.children"
          :key="`submenu-${baseMenuName}-${sublist.modifier}-${submenu.id}`"
          @click="closeOverlay">
          <router-link class="jwl-menu-overlay__sublist-item-label"
            :to="toRoute(submenu)" @keyup.enter="closeOverlay">
            {{submenu.label}}
            <small>
              {{submenu.code}}
              <template v-if="submenu.startDate && submenu.endDate">
                // {{formattedDate(submenu.startDate)}} - {{formattedDate(submenu.endDate)}}
              </template>
            </small>
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
  <div class="flex justify-center mt-default" v-else-if="!loaded">
    <jwl-icon loading-icon class="icon-big" />
  </div>
  <div v-else>
    <div class="flex justify-center mt-default" v-if="baseConcentrations?.error">
      <jwl-icon icon-style="regular" icon-name="exclamation-circle" marginRight class="icon-big" /> {{ $t('menu.hint.menuError') }}
      <br/>
      ({{ baseConcentrations?.error }})
    </div>
    <div  class="flex justify-center mt-default" v-else>
      <jwl-icon icon-style="regular" icon-name="clock" marginRight class="icon-big" /> {{ $t('menu.hint.emptySubMenu') }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import useModuleStore from '@base/store/moduleStore';
import JwlIcon from '@base/components/JwlIcon.vue';

export default {
  components: {
    JwlIcon,
  },
  props: {
    baseMenuName: String,
  },
  methods: {
    submenuListItemClass (modifier) {
      return `jwl-menu-overlay__list-item--${modifier}`;
    },
    closeOverlay () {
      this.$emit('close-overlay');
    },
    formattedDate (dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },
    toRoute (submenu) {
      if (submenu.type === 'orientation') {
        return { name: 'orientation_course', params: { courseId: submenu.id } };
      }

      return { name: this.baseMenuName, params: { concentration: submenu.code } };
    },
  },
  computed: {
    menu () {
      const menuTrees = [];
      if (this.baseConcentrations) {
        const concentrationMenu = this.baseConcentrations.menu();

        if (concentrationMenu.bachelor.children.length > 0) {
          menuTrees.push(concentrationMenu.bachelor);
        }
        if (concentrationMenu.professional.children.length > 0) {
          menuTrees.push(concentrationMenu.professional);
        }
        if (this.baseMenuName === 'concentration_home' && concentrationMenu.orientation.children.length > 0) {
          menuTrees.push(concentrationMenu.orientation);
        }
      }
      return menuTrees;
    },
    loaded () {
      return this.baseConcentrations !== null;
    },
    ...mapState(useModuleStore, ['baseConcentrations']),
  },
};
</script>

<style lang="scss">
@use "../styles/variables";
@use "../styles/mixins";

.jwl-menu-overlay {
  $root: &;

  align-items: flex-start;
  box-sizing: border-box;
  display: grid;
  grid-auto-columns: 33.33%;
  grid-auto-flow: column;
  grid-gap: 1rem;
  grid-template-columns: 33.33%;
  grid-template-rows: auto;
  justify-content: flex-start;
  left: 0;
  list-style: none;
  padding: 1rem 1.5rem;
  top: 100%;

  @include mixins.mq(variables.$mq-phone) {
    grid-auto-columns: 100%;
    grid-auto-flow: row;
    grid-template-columns: 100%;
    padding: 1rem 2.5vw;
  }

  ul {
    list-style: none;
  }

  &__list-item {
    --node-theme: var(--color-gray-600);

    display: flex;
    flex: 0 0 calc(33.33% - 1.5rem);
    flex-flow: column;

    &--bachelor {
      --node-theme: var(--color-indigo);
    }

    &--professional {
      --node-theme: var(--color-orange);
    }

    &--language {
      --node-theme: var(--color-red);
    }

    &--orientation {
      --node-theme: var(--color-blue);
    }
  }

  &__list-item-label {
    border-bottom: 1px solid;
    color: var(--node-theme);
    margin-bottom: 1rem;
    padding: .4rem 0;
    text-transform: uppercase;
  }

  &__sublist {
    padding: 0 0;

    &--contains-toggle {
      padding: 0 0 0 1.5rem;
    }
  }

  &__sublist-item {
    margin-bottom: 1rem;
  }

  &__sublist-item-label {
    color: var(--color-gray-900);
    display: block;
    font-size: 1rem;
    text-decoration: none;
    transition: color .25s;

    &:hover {
      color: var(--node-theme);
    }

    small {
      color: var(--color-gray-600);
      display: block;
      margin-top: 0;
      width: 100%;
    }
  }
}
</style>
