import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
} from 'axios';

export const ACCESS_TOKEN_KEY = 'authCode';
export const REFRESH_TOKEN_KEY = 'refreshCode';
export const IMPERSONATE_KEY = 'impersonateUsername';
export const BASE_URL = import.meta.env.VITE_VUE_APP_API_BASE_URL;

export default class JwlApi {
  #accessToken: string | null = '';
  #refreshToken: string | null = '';
  #impersonate: string | null = '';
  #tokenType = 'Bearer';
  readonly #axios: AxiosInstance;
  readonly #publicAxios: AxiosInstance;
  #isAlreadyFetchingAccessToken = false;
  #subscribers: Array<() => void> = [];
  #debugEnabled = false;

  constructor () {
    this.restoreData();

    this.#axios = axios.create({
      baseURL: BASE_URL,
    });

    this.#publicAxios = axios.create({
      baseURL: BASE_URL,
    });

    if (this.#accessToken) {
      this.setAuthorizationHeaders();
    }

    this.#axios.interceptors.request.use(
      (config) => {
        if (this.#impersonate && config.url && !config.url.includes('impersonators')) {
          if (!config.url.includes('?_switch_user')) {
            config.url += `?_switch_user=${this.#impersonate}`;
          }
        } else if (this.#debugEnabled) {
          config.url += '?XDEBUG_TRIGGER=trigger';
        }
        return config;
      },
    );

    this.#axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        const errorResponse = error.response;
        if (errorResponse?.status === 401) {
          return this.resetTokenAndReattemptRequest(error);
        }
        // If the error is due to other reasons, we just throw it back to axios
        return Promise.reject(error);
      },
    );
  }

  get axios (): AxiosInstance {
    return this.#axios;
  }

  get publicAxios (): AxiosInstance {
    return this.#publicAxios;
  }

  login (token: string, refreshToken: string): void {
    this.#accessToken = token;
    this.#refreshToken = refreshToken;
  }

  deprecatedLogin (username: string, password: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (localStorage.getItem(IMPERSONATE_KEY)) {
        localStorage.removeItem(IMPERSONATE_KEY);
        this.#impersonate = '';
      }
      const loginAxios = axios.create({
        baseURL: BASE_URL,
      });
      loginAxios({
        url: '/api/login_check',
        method: 'post',
        data: {
          username,
          password,
        },
      }).then((response) => {
        this.#accessToken = response.data.token;
        this.#refreshToken = response.data.refresh_token;

        this.#axios.defaults.headers.common.Authorization = `${this.#tokenType} ${this.#accessToken}`;
        this.saveData();
        resolve();
      }).catch((e) => {
        reject(e);
      });
    });
  }

  logout (): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(IMPERSONATE_KEY);

    this.#accessToken = '';
    this.#refreshToken = '';
  }

  async resetTokenAndReattemptRequest (error: AxiosError): Promise<AxiosPromise | void> {
    try {
      const { response: errorResponse } = error;
      const resetToken = this.#refreshToken;
      if (!resetToken || !errorResponse?.config) {
        // We can't refresh, throw the error anyway
        return Promise.reject(error);
      }
      /* Proceed to the token refresh procedure
      We create a new Promise that will retry the request,
      clone all the request configuration from the failed
      request in the error object. */
      const retryOriginalRequest = new Promise<AxiosPromise>((resolve) => {
        /* We need to add the request retry to the queue
        since there another request that already attempt to
        refresh the token */
        this.addSubscriber(() => {
          errorResponse.config.headers.Authorization = `${this.#tokenType} ${this.#accessToken}`;
          resolve(this.#axios(errorResponse.config));
        });
      });
      if (!this.#isAlreadyFetchingAccessToken) {
        this.#isAlreadyFetchingAccessToken = true;
        const refreshAxios = axios.create({
          baseURL: BASE_URL,
        });
        const response = await refreshAxios({
          method: 'post',
          url: '/api/token/refresh',
          data: `refresh_token=${resetToken}`,
        });
        if (!response.data || response.status === 401) {
          return Promise.reject(error);
        }
        this.#accessToken = response.data.token;
        this.#refreshToken = response.data.refresh_token;
        this.saveData();
        this.#isAlreadyFetchingAccessToken = false;
        this.onAccessTokenFetched();
      }
      return retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  onAccessTokenFetched (): void {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    this.#subscribers.forEach((callback) => callback());
    this.#subscribers = [];
  }

  addSubscriber (callback: () => void): void {
    this.#subscribers.push(callback);
  }

  saveData (): void {
    if (this.#accessToken && this.#refreshToken) {
      localStorage.setItem(ACCESS_TOKEN_KEY, this.#accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, this.#refreshToken);
    }
  }

  restoreData (): void {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      this.#accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    }

    if (localStorage.getItem(REFRESH_TOKEN_KEY)) {
      this.#refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    }

    if (localStorage.getItem(IMPERSONATE_KEY)) {
      this.#impersonate = localStorage.getItem(IMPERSONATE_KEY);
    }

    if (localStorage.getItem('xdebug_enabled')) {
      this.#debugEnabled = true;
    }
  }

  setAuthorizationHeaders (): void {
    this.#axios.defaults.headers.common.Authorization = `${this.#tokenType} ${this.#accessToken}`;
    this.#publicAxios.defaults.headers.common.Authorization = `${this.#tokenType} ${this.#accessToken}`;
  }
}
