import {
  Route,
  RouteConfig,
} from 'vue-router';
import JwlRouterView from '../components/jwlRouterView.vue';

export const handleLoginRoute: RouteConfig = {
  path: '/:lang/handle-login',
  name: 'handle_login',
  component: () => import('../components/JWL_HandleLogin.vue'),
};

export const loginRoute: RouteConfig = {
  path: '/:lang/login',
  name: 'login',
  component: () => import('../components/JWL_Login.vue'),
};

export const forgotPasswordRoute: RouteConfig = {
  path: '/:lang/forgot-password',
  name: 'forgot_password',
  component: () => import('../components/JWL_ForgotPassword.vue'),
};

export const profileRoute: RouteConfig = {
  path: '/:lang/profile',
  name: 'profileWrapper',
  component: JwlRouterView,
  redirect: (to: Route) => ({ name: 'profile', lang: to.params.lang }),
  children: [
    {
      path: 'view',
      name: 'profile',
      component: () => import('../components/profile/CommonProfile.vue'),
    }, {
      path: 'change-password',
      name: 'change_password',
      component: () => import('../components/profile/CommonChangePassword.vue'),
    }, {
      path: 'image-upload/:imageType?',
      name: 'image_upload',
      component: () => import('../components/profile/CommonImageUpload.vue'),
    },
  ],
};
