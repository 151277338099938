<template>
  <div class="jwl-footer">
    <div class="jwl-rainbow-bar" />

    <div class="jwl-footer__container">
      <a class="jwl-footer__link" href="https://www.jwl.org/en/greater-good-principles" target="_blank">
        {{ $t('footer.acceptableUsePolicy') }}
      </a>

      <span>Version {{ moduleStore.version }}</span>

      <bdo class="jwl-footer__copyright" dir="ltr">
        &copy; {{year}} {{$t('common.jwl')}}
      </bdo>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useModuleStore } from '@base/store/moduleStore';

export default defineComponent({
  name: 'jwl-footer',
  setup () {
    const moduleStore = useModuleStore();
    const year = new Date().getUTCFullYear();

    return {
      moduleStore,
      year,
    };
  },
});
</script>

<style lang="scss">
@use "../styles/variables";
@use "../styles/mixins";

.jwl-footer {
  align-items: center;
  background-color: var(--color-gray-300);
  bottom: calc(var(--footer-height) * -1);
  box-sizing: border-box;
  display: flex;
  height: var(--footer-height);
  justify-content: center;
  left: 0;
  margin-top: auto;
  position: absolute;
  width: 100%;
  z-index: 100;

  @include mixins.mq(variables.$mq-phone) {
    font-size: .8rem;
  }

  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: var(--container-width);
    width: 95%;
  }

  &__link {
    text-decoration: none;
    color: var(--color-black);
  }
}
</style>
