import { LocaleMessages } from 'vue-i18n';

export interface SelectOption {
  id: string | null;
  label: string | LocaleMessages | null;
  group?: SelectOption[] | null;
}

export interface TermSelectOption extends SelectOption {
  isCurrent: boolean;
}

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class FormElementOptions {
  required = false;
  fieldName = '';
  fieldType = 'text';
  selectOptions: SelectOption[] | null;
  translateKey = '';
  translatedLabel = true;
  emptyValue: any | null = null;
  pluginOptions: any | null = null;
  minWordLength: number | null = null;

  constructor (fieldName: string, fieldType: string, required = false, selectOptions: SelectOption[] | null = null, translatedLabel = true, translateKey: string | null = null) {
    this.required = required;
    this.fieldName = fieldName;
    if (fieldType === 'select' && (!selectOptions || selectOptions.length === 0)) {
      this.fieldType = 'text';
    } else {
      this.fieldType = fieldType;
    }
    this.selectOptions = selectOptions;
    this.translatedLabel = translatedLabel;
    this.translateKey = translateKey || fieldName;
  }

  valid (value: any): boolean {
    switch (this.fieldType) {
      case 'text':
      case 'textarea':
        if (this.minWordLength && this.minWordLength > 0) {
          return value && value.split(' ').length >= this.minWordLength;
        }
        return this.required
          ? (value && value.length >= 2)
          : true;
      case 'number':
        return this.required || value
          ? typeof value === 'number'
          : true;
      case 'email':
        return this.required || value
          ? EMAIL_REGEX.test(value)
          : true;
      case 'select':
      case 'country':
        return this.required
          ? (value && value.id != null)
          : true;
      case 'phone':
        return this.required || value
          ? !!value
          : true;
      case 'boolean':
        return this.required
          ? value === true
          : true;
      case 'date': {
        const date = new Date(value);
        return this.required || value
          ? !Number.isNaN(date.getTime())
          : true;
      }
      case 'multiselect':
      case 'multi-select':
        return this.required
          ? value.length > 0
          : true;
      default:
        return !!value;
    }
  }

  errorMessage (value: any): string {
    if (this.minWordLength && this.minWordLength > 0) {
      return this.valid(value)
        ? ''
        : 'formElement.error.notEnoughWords';
    }
    if (!this.valid(value)) {
      return `formElement.error.${this.fieldType}`;
    }
    return '';
  }

  getEmptyValue () {
    if (this.emptyValue) {
      return this.emptyValue;
    }
    switch (this.fieldType) {
      case 'text':
      case 'textarea':
      case 'email':
      case 'phone':
        return '';
      case 'number':
        return 0;
      case 'select':
      case 'country':
      case 'date':
        return null;
      case 'boolean':
        return false;
      case 'multiselect':
      case 'multi-select':
        return [];
      default:
        return null;
    }
  }

  isInputType () {
    const inputCases = ['text', 'email', 'file'];
    return inputCases.indexOf(this.fieldType) >= 0;
  }

  isNumberType () {
    return this.fieldType === 'number';
  }

  isBooleanType () {
    return this.fieldType === 'boolean';
  }

  isTextareaType () {
    return this.fieldType === 'textarea';
  }

  isSelectType () {
    return this.fieldType === 'select';
  }

  isMultiSelectType () {
    return this.fieldType === 'multi-select' || this.fieldType === 'multiselect';
  }

  isCountryType () {
    return this.fieldType === 'country';
  }

  isDateType () {
    return this.fieldType === 'date';
  }

  isPhoneType () {
    return this.fieldType === 'phone';
  }

  initialView () {
    return this.pluginOptions?.initialView
      ? this.pluginOptions.initialView
      : 'year';
  }

  highlightedDates () {
    return this.pluginOptions?.highlighted
      ? this.pluginOptions.highlighted
      : {
        dates: [
          new Date(),
        ],
      };
  }
}
