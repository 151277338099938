<template>
  <div class="jwl-toggle" :class="openClass">
    <div class="jwl-toggle__bar jwl-toggle__bar--horizontal"></div>
    <div class="jwl-toggle__bar jwl-toggle__bar--vertical"></div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class JwlToggle extends Vue {
  @Prop(Boolean)
  isOpen!: boolean;

  get openClass (): Record<string, boolean> {
    return {
      'jwl-toggle--open': this.isOpen,
    };
  }
}
</script>

<style lang="scss">
@use "@base/styles/mixins";
@use "@base/styles/variables";

.jwl-toggle {
  $root: &;

  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: 2rem;
  grid-template-rows: 2rem;
  justify-content: center;

  @include mixins.mq(variables.$mq-phone) {
    grid-template-columns: 1.15rem;
    grid-template-rows: 1.15rem;
  }

  &__bar {
    background-color: var(--toggle-color, var(--page-color, var(--color-white)));
    border-radius: 1px;
    grid-column: 1;
    grid-row: 1;
    height: .3rem;
    transition: transform .25s;
    width: 2rem;

    @include mixins.mq(variables.$mq-phone) {
      height: .16rem;
      width: 1.15rem;
    }

    &--vertical {
      transform: rotate(90deg);

      #{$root}--open & {
        transform: rotate(0);
      }
    }
  }
}

</style>
