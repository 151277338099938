<template>
  <div class="form-element" :class="rootFormClass" :key="fieldName">
    <label
      v-if="fieldTranslateKey && !hideLable"
      class="form-element__label"
      :class="labelClass"
      :for="fieldId"
      v-html="$t(`formElement.${fieldTranslateKey}`)">
    </label>

    <label v-else-if="!hideLable"
      class="form-element__label"
      :class="labelClass"
      :for="fieldId">
      <div v-if="freeTextLabel">
        {{ freeTextLabel }}
      </div>
      <slot v-else>
        {{$t(`formElement.${fieldName}`)}}
      </slot>

    </label>

    <div class="form-element__boolean" v-if="isBooleanType">
      <input
        class="form-element__checkbox"
        :id="fieldId"
        :name="fieldName"
        type="checkbox"
        :value="elementValue"
        :checked="elementValue"
        :required="required"
        :disabled="fieldDisabled"
        @input="validate(!!$event.target.checked)"
        @change="validate(!!$event.target.checked)"/>
      <div
        class="form-element__boolean-input"
        :class="checkedClass"
        @click="validate(!elementValue)">
        <icon-check v-show="elementValue"/>
      </div>
    </div>

    <div class="form-element__boolean" v-if="isRadioType">
      <input
        class="form-element__checkbox"
        :id="fieldId"
        :name="fieldName"
        type="radio"
        :value="getFieldValue"
        :checked="emptyValue"
        :required="required"
        :disabled="fieldDisabled"
        @change="validate($event.target.value)"/>
    </div>

    <input
      v-else-if="isInputType"
      class="form-element__input"
      :id="fieldId"
      :name="fieldName"
      :type="fieldType"
      :placeholder="fieldPlaceholder"
      :value="elementValue"
      :required="required"
      :disabled="fieldDisabled"
      @blur="validate($event.target.value)"
      @change="validate($event.target.value)"/>

    <input
      v-else-if="isNumberType"
      class="form-element__input"
      :id="fieldId"
      :name="fieldName"
      type="number"
      :placeholder="fieldPlaceholder"
      :value="elementValue"
      :required="required"
      :disabled="fieldDisabled"
      @change="validate(Number($event.target.value))"
      @blur="validate(Number($event.target.value))"/>

    <input
      v-else-if="isFileType"
      class="form-element__input"
      :id="fieldId"
      :name="maxFiles > 1 ? `${fieldName}[]` : fieldName"
      type="file"
      :multiple="maxFiles > 1"
      :required="required"
      :disabled="fieldDisabled"
      accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.csv,.xls,.xlsx,.ppt,.pptx,.java,.ipynb"
      @change="validate($event.target.files)"/>

    <textarea
      v-else-if="isTextareaType"
      class="form-element__textarea"
      :id="fieldId"
      :value="elementValue"
      :required="required"
      :disabled="fieldDisabled"
      :name="fieldName"
      :minlength="minWordLength"
      @blur="validate($event.target.value)"/>

    <template v-else-if="isSelectType || isCountryType">
      <v-select
        class="form-element__select"
        :class="multiselectClasses"
        track-by="id"
        :input-id="fieldName"
        :value="elementValue"
        :allow-empty="!required"
        :group-label="groupLabel"
        :group-values="groupValue"
        :options="selectOptions"
        @input="validate($event)"
        :disabled="fieldDisabled"
        :custom-label="selectLabel"/>

      <input
        class="form-element__hidden-input"
        type="text"
        :required="required"
        :disabled="fieldDisabled"
        :name="fieldName"
        :value="elementValue ? elementValue.id : getEmptyValue"/>
    </template>

    <template v-else-if="isMultiSelectType">
      <v-select
        class="form-element__multi-select"
        :input-id="fieldId"
        multiple
        track-by="id"
        label="label"
        :value="elementValue"
        :allow-empty="!required"
        :options="fieldOptions"
        :disabled="fieldDisabled"
        @input="validate"
        @search="validate"/>

      <input
        class="form-element__hidden-input"
        type="text"
        :required="required"
        :disabled="fieldDisabled"
        :name="fieldName"
        :value="joinedElementValues"/>
    </template>

    <v-datepicker
      v-else-if="isDateType"
      class="form-element__date"
      :value="elementValue"
      @selected="validate($event)"
      :required="required"
      :name="fieldName"
      :initial-view="initialView"
      :highlighted="highlightedDates"
      :disabled="fieldDisabled"
      :typeable="true"
      :use-utc="false"/>

    <v-phone-input
      v-else-if="isPhoneType"
      ref="phoneInput"
      class="form-element__phone"
      :id="fieldId"
      v-model="elementValue"
      :required="required"
      :disabled="fieldDisabled"
      :name="fieldName"
      :no-country-selector="false"
      color="#00ADEF"
      valid-color="#ced4da"
      error-color="#93142A"
      :border-radius="0"
      :no-example="false"
      :no-use-browser-locale="true"
      @update="validate"/>

    <div class="form-element__error-message" v-if="validated && !valid">
      {{ $t(getErrorMessage) }}
    </div>

    <slot name="hint">
      <small class="form-element__hint" v-if="fieldHint && fieldHintTranslate"
             v-html="$t(fieldHint, fieldHintParams)"/>
      <small class="form-element__hint" v-else-if="fieldHint" v-html="fieldHint"/>
    </slot>
  </div>
</template>

<script lang="ts">

import Vue from 'vue';
import Component from 'vue-class-component';
import { Model, Prop } from 'vue-property-decorator';
import countries from 'i18n-iso-countries/langs/en.json';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { SelectOption } from '@base/helper/FormElementOptions';
import { LocaleMessages } from 'vue-i18n';

const Datepicker = () => import('vuejs-datepicker');
const Multiselect = () => import('vue-multiselect');
const VuePhoneNumberInput = () => import('vue-phone-number-input');
const IconCheck = () => import('../assets/fa-icons/regular/check.svg');

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  components: {
    IconCheck,
    'v-datepicker': Datepicker,
    'v-select': Multiselect,
    'v-phone-input': VuePhoneNumberInput,
  },
})
export default class FormElement extends Vue {
  $refs!: {
    phoneInput;
  };

  @Model('change', [String, Number, Boolean, Object, Date, Array])
  value!: string | number | boolean | Record<string, any> | Date | any[];

  @Prop([String, Number, Boolean, Object, Date, Array])
  emptyValue!: string | number | boolean | Record<string, any> | Date | [];

  @Prop(String)
  fieldValue!: string;

  @Prop(Boolean)
  required!: boolean;

  @Prop({ type: Number, default: 1 })
  maxFiles!: number;

  @Prop(Number)
  minWordLength!: number;

  @Prop(Number)
  maxWordLength!: number;

  @Prop(String)
  fieldName!: string;

  @Prop(String)
  fieldTranslateKey!: string;

  @Prop({ type: String, default: 'text' })
  fieldType!: 'text' | 'textarea' | 'password' | 'number' | 'email' | 'select' | 'country' | 'phone' | 'boolean' | 'radio' | 'date' | 'multiselect' | 'multi-select' | 'file' | 'ckeditor';

  @Prop(Array)
  fieldOptions!: any[];

  @Prop({ type: Boolean, default: false })
  fieldOptionsTranslate!: boolean;

  @Prop(String)
  fieldPlaceholder!: string;

  @Prop(Boolean)
  fieldDisabled!: boolean;

  @Prop(Boolean)
  hideLable!: boolean;

  @Prop(String)
  freeTextLabel!: string;

  @Prop({ type: String, default: 'year' })
  initialView!: string;

  @Prop({
    type: Object,
    default () {
      return { dates: [new Date()] };
    },
  })
  highlightedDates!: Record<string, any>;

  @Prop(String)
  fieldHint!: string;

  @Prop(Object)
  fieldHintParams!: Record<string, string | number>;

  @Prop({ type: Boolean, default: true })
  fieldHintTranslate!: boolean;

  @Prop(String)
  selectorPath!: string;

  @Prop({ type: String, default: 'single-row' })
  formStyle!: 'single-row' | 'multi-row' | 'inline' | string;

  validated = false;
  valid = !this.required;
  errorMessage: string | null = null;
  elementValue = this.getEmptyValue;
  componentSpecificOptions = {};
  editor = null;
  editorConfig = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    mediaEmbed: {
      removeProviders: ['vimeo', 'youtube'],
    },
  };

  selectLabel ({ label }: SelectOption): string | LocaleMessages | null {
    return label;
  }

  validate (newValue: any): void {
    if (this.fieldDisabled) {
      return;
    }

    this.errorMessage = null;
    this.valid = !!newValue;
    if (['text', 'textarea', 'ckeditor'].includes(this.fieldType)) {
      this.validateText(newValue);
    } else if (this.fieldType === 'number') {
      this.validateNumber(newValue);
    } else if (this.fieldType === 'email') {
      this.validateEmail(newValue);
    } else if (['select', 'country'].includes(this.fieldType)) {
      this.validateSelect(newValue);
    } else if (this.fieldType === 'phone') {
      this.validatePhone(newValue);
    } else if (this.fieldType === 'boolean') {
      this.validateBoolean(newValue);
    } else if (this.fieldType === 'date') {
      this.validateDate(newValue);
    } else if (['multiselect', 'multi-select'].includes(this.fieldType)) {
      this.validateMultiSelect(newValue);
    } else if (this.fieldType === 'file') {
      this.validateFile(newValue);
    }
    this.validated = true;

    if (this.fieldType === 'phone') {
      this.elementValue = newValue.formatInternational;
    } else if (['text', 'textarea', 'ckeditor'].includes(this.fieldType)) {
      this.elementValue = newValue.trim();
    } else {
      this.elementValue = newValue;
    }

    this.$emit('change', this.elementValue);
    this.$emit('valid', this.valid);
  }

  validateText (newValue: string): void {
    this.valid = this.required
      ? !!newValue && newValue.length >= 1
      : true;

    if ((this.minWordLength) || (this.maxWordLength)) {
      const wordCount = newValue.split(' ').length;
      const satisfiesMin = this.minWordLength ? wordCount > this.minWordLength : true;
      const satisfiesMax = this.maxWordLength ? wordCount < this.maxWordLength : true;
      if (!satisfiesMin) {
        this.errorMessage = 'formElement.error.notEnoughWords';
      }
      if (!satisfiesMax) {
        this.errorMessage = 'formElement.error.tooManyWords';
      }

      this.valid = satisfiesMin && satisfiesMax;
    }
  }

  validateNumber (newValue: number | null): void {
    this.valid = this.required || newValue
      ? typeof newValue === 'number'
      : true;
  }

  validateEmail (newValue: string): void {
    this.valid = this.required || newValue
      ? EMAIL_REGEX.test(newValue)
      : true;
  }

  validateSelect (newValue: any): void {
    this.valid = this.required
      ? (newValue && newValue.id != null)
      : true;
  }

  validateMultiSelect (newValue: []): void {
    this.valid = this.required
      ? newValue.length > 0
      : true;
  }

  validatePhone (newValue: any): void {
    this.valid = this.required || newValue
      ? !!newValue.phoneNumber && !!newValue.countryCode
      : true;
  }

  validateBoolean (newValue: boolean | null): void {
    this.valid = this.required
      ? newValue === true
      : true;
  }

  validateDate (newValue: string): void {
    const date = new Date(newValue);
    this.valid = this.required || newValue
      ? !Number.isNaN(date.getTime())
      : true;

    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    if (this.fieldName.toLowerCase().includes('birth')) {
      if (age < 15 || age > 99) {
        this.errorMessage = 'formElement.error.none-plausible-date-of-birth';
        this.valid = false;
      }
    }
  }

  validateFile (newValue: any): void {
    this.valid = newValue && newValue.length > 0;
  }

  get isInputType (): boolean {
    const inputCases = ['text', 'email', 'password'];
    return inputCases.indexOf(this.fieldType) >= 0;
  }

  get isNumberType (): boolean {
    return this.fieldType === 'number';
  }

  get isBooleanType (): boolean {
    return this.fieldType === 'boolean';
  }

  get isRadioType (): boolean {
    return this.fieldType === 'radio';
  }

  get isTextareaType (): boolean {
    return this.fieldType === 'textarea' || this.isCkeditorType;
  }

  get isCkeditorType (): boolean {
    return this.fieldType === 'ckeditor';
  }

  get isSelectType (): boolean {
    return this.fieldType === 'select';
  }

  get isMultiSelectType (): boolean {
    return this.fieldType === 'multi-select' || this.fieldType === 'multiselect';
  }

  get isCountryType (): boolean {
    return this.fieldType === 'country';
  }

  get isDateType (): boolean {
    return this.fieldType === 'date';
  }

  get isPhoneType (): boolean {
    return this.fieldType === 'phone';
  }

  get isFileType (): boolean {
    return this.fieldType === 'file';
  }

  get getEmptyValue (): any {
    if (this.emptyValue) {
      return this.emptyValue;
    }

    switch (this.fieldType) {
      case 'text':
      case 'textarea':
      case 'ckeditor':
      case 'email':
      case 'phone':
        return '';
      case 'number':
        return 0;
      case 'select':
      case 'country':
        return '';
      case 'date':
        return null;
      case 'boolean':
        return false;
      case 'multiselect':
      case 'multi-select':
        return [];
      default:
        return null;
    }
  }

  get getErrorMessage (): string | null {
    if (this.valid) {
      return null;
    }

    return this.errorMessage || `formElement.error.${this.fieldType}`;
  }

  get joinedElementValues (): string | null {
    return this.isMultiSelectType && this.elementValue
      ? this.elementValue.map((value: SelectOption) => value.id).join(',')
      : null;
  }

  get checkedClass (): Record<string, boolean> {
    return {
      'form-element__boolean-input--checked': this.elementValue === true,
    };
  }

  get rootFormClass (): Record<string, boolean> {
    return {
      'form-element--single-row': this.formStyle === 'single-row',
      'form-element--multi-row': this.formStyle === 'multi-row',
      'form-element--inline': this.formStyle === 'inline',
      'form-element--valid': this.validated && this.valid,
      'form-element--invalid': this.validated && !this.valid,
      'form-element--boolean': this.isBooleanType || this.isRadioType,
      'form-element--disabled': this.fieldDisabled,
      'form-element--required': this.required && !this.elementValue,
      // 'form-element--not-empty': this.fieldType === 'select' && this.elementValue.id,
    };
  }

  get labelClass (): Record<string, boolean> {
    return {
      'form-element__label--required': this.required && !this.isRadioType,
    };
  }

  get getFieldValue (): string {
    if (this.fieldValue) {
      return this.fieldValue;
    }

    if (this.isBooleanType) {
      return 'true';
    }

    return '';
  }

  get fieldId (): string {
    if (this.isRadioType) {
      return `${this.fieldName}-${this.getFieldValue}-id`;
    }

    return this.fieldName;
  }

  get selectHasGroup (): boolean {
    return !!this.selectOptions?.find((option) => option.group);
  }

  get groupLabel (): string | undefined {
    if (this.selectOptions) {
      return this.selectHasGroup ? 'label' : undefined;
    }

    return undefined;
  }

  get groupValue (): string | undefined {
    if (this.selectOptions) {
      return this.selectHasGroup ? 'group' : undefined;
    }

    return undefined;
  }

  get multiselectClasses (): Record<string, boolean> {
    return {
      'multiselect--groups': this.selectHasGroup,
    };
  }

  get selectOptions (): SelectOption[] | null {
    if (this.isCountryType) {
      const selectList: SelectOption[] = [];
      const countryList: Record<string, string | string[]> = countries.countries;
      Object.keys(countryList).forEach((countryCode) => {
        const countryName = countryList[countryCode];
        selectList.push({
          id: countryCode,
          label: Array.isArray(countryName) ? countryName.join(' / ') : countryName,
        });
      });
      return selectList;
    }

    if (this.fieldOptionsTranslate) {
      const selectList: SelectOption[] = [];
      this.fieldOptions.forEach((fieldOption) => {
        if (fieldOption.label) {
          selectList.push({
            id: fieldOption.id,
            label: this.$root.$t(fieldOption.label.toString()),
          });
        }
      });
      return selectList;
    }

    return this.fieldOptions;
  }

  get selectValue (): SelectOption | SelectOption[] | undefined {
    const localValue = this.value;
    if (this.isMultiSelectType && localValue && Array.isArray(localValue)) {
      return this.selectOptions?.filter((selectOption) => localValue.includes(selectOption.id));
    }

    if (!this.isMultiSelectType && localValue && typeof localValue === 'object' && 'id' in localValue) {
      return {
        id: localValue.id,
        label: localValue.label,
      };
    }

    if (localValue) {
      return this.selectOptions?.find((selectOption) => selectOption.id === localValue);
    }

    return undefined;
  }

  mounted (): void {
    if (this.value) {
      this.elementValue = this.value;
    }
    if (this.isSelectType || this.isCountryType || this.isMultiSelectType) {
      this.elementValue = this.selectValue;
    }
    if (this.isPhoneType) {
      setTimeout(() => {
        this.$refs.phoneInput.$refs.CountrySelector.$refs.CountrySelector.required = true;
        this.$refs.phoneInput.$refs.CountrySelector.$refs.CountrySelector.removeAttribute('readonly');
        this.$refs.phoneInput.$refs.CountrySelector.$refs.CountrySelector.name = 'phoneCountryCode';
      }, 500);
    }
  }
}
</script>

<style lang="scss">
@use "../styles/variables";
@use "../styles/mixins";
@use "../styles/multiselect";

.form-element {
  $root: &;

  --form-border-color: var(--color-gray-200);
  --form-border-color-hover: var(--opaque-blue);
  --form-border-color-active: var(--color-blue);

  align-items: baseline;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 1rem;
  width: 100%;

  &--boolean {
    padding-left: 20%;
    width: 80%;

    @include mixins.mq(variables.$mq-phone) {
      padding-left: 0;
      width: 100%;
    }

    #{$root}__label {
      flex: 0 0 calc(100% - 4rem);
      order: 1;
    }

    &:not(#{$root}--disabled) #{$root}__label {
      &:hover ~ #{$root}__boolean {
        #{$root}__boolean-input {
          border-color: var(--form-border-color-hover);
        }
      }

      &:active ~ #{$root}__boolean {
        #{$root}__boolean-input {
          border-color: var(--form-border-color-active);
        }
      }
    }

    #{$root}__error-message {
      flex: 0 0 100%;
      margin-left: 1.75rem;
    }
  }

  &--multi-row {
    padding-left: 0;
    width: 100%;
  }

  &--inline {
    margin-bottom: 0;
    position: relative;

    #{$root}__label {
      background-color: var(--color-white);
      border-radius: 1px;
      font-size: .8rem;
      left: .35rem;
      padding: 0 .25rem;
      position: absolute;
      top: -.5rem;
      z-index: 1;
    }

    #{$root}__input {
      flex: 0 0 100%;
      padding: .25rem .5rem;
    }

    #{$root}__select,
    #{$root}__date {
      flex: 0 0 100%;
    }
  }

  &__boolean {
    align-items: center;
    display: grid;
    grid-template-columns: 1.5rem;
    grid-template-rows: 1.5rem;
    justify-content: center;
    margin-right: 1rem;
    order: 0;
  }

  &__checkbox,
  &__boolean-input {
    grid-column: 1;
    grid-row: 1;
  }

  &__boolean-input {
    align-items: center;
    background-color: var(--color-white);
    border: 2px solid var(--form-border-color);
    border-radius: var(--border-radius);
    display: flex;
    height: calc(100% - 4px);
    justify-content: center;
    transition: border-color .25s;
    width: calc(100% - 4px);

    &:hover {
      border-color: var(--form-border-color-hover);
    }

    &:active {
      border-color: var(--form-border-color-active);
    }

    &:disabled,
    #{$root}--disabled & {
      cursor: not-allowed;

      &:hover,
      &:active,
      &:focus {
        border-color: var(--color-gray-500);
      }
    }
  }

  &__label {
    flex: 0 0 calc(20% - 1rem);
    padding-right: 1rem;

    #{$root}--multi-row:not(#{$root}--boolean) & {
      flex: 0 0 calc(100% - 1rem);
    }

    @include mixins.mq(variables.$mq-phone) {
      flex: 0 0 calc(100% - 1rem);
    }

    &--required::after {
      content: ' *';
      white-space: nowrap;
    }
  }

  &__elements {
    flex: 0 0 calc(80% - 1.5rem - 4px);

    #{$root}--multi-row & {
      flex: 0 0 calc(100% - 1.5rem - 4px);
    }

    @include mixins.mq(variables.$mq-phone) {
      flex: 0 0 calc(100% - 1.5rem - 4px);
    }
  }

  &__input,
  &__textarea,
  &__date input {
    border: 2px solid var(--form-border-color);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    flex: 0 0 80%;
    font-size: 1rem;
    font-weight: variables.$font-weight-normal;
    padding: .5rem .75rem;
    transition: border-color .25s;
    width: 100%;

    @include mixins.mq(variables.$mq-phone) {
      flex: 0 0 100%;
    }

    &--error,
    &:invalid {
      border-color: var(--color-error);

      &::file-selector-button {
        background-color: var(--color-error);
        color: var(--color-white);
      }
    }

    &:hover {
      border-color: var(--form-border-color-hover);

      &::file-selector-button {
        background-color: var(--form-border-color-hover);
        border-right-color: var(--form-border-color-hover);
        color: var(--color-white);
      }
    }

    &:active,
    &:focus {
      border-color: var(--form-border-color-active);
      outline: none;
    }

    #{$root}--multi-row & {
      flex: 0 0 calc(100% - 1.5rem - 4px);
    }

    &[type="file"] {
      padding: 0 0;
    }

    &::file-selector-button {
      @include mixins.ltrtl(margin-right, margin-left, .5rem);

      background: variables.$bg;
      border: none;
      border-right: 2px solid var(--form-border-color);
      padding: .5rem .75rem;
      transition: background-color .25s, border-right-color .25s, color .25s;
    }

    #{$root}--multi-row & {
      flex: 0 0 100%;
    }
  }

  &__date {
    flex: 0 0 80%;
    width: 100%;

    input {
      flex-basis: 100%;
    }

    @include mixins.mq(variables.$mq-phone) {
      flex: 0 0 100%;
    }

    #{$root}--multi-row & {
      flex: 0 0 100%;
    }
  }

  &__phone {
    flex: 0 0 80%;
    width: 100%;

    @include mixins.mq(variables.$mq-phone) {
      flex: 0 0 100%;
    }

    .input-tel__input {
      border: 2px solid var(--form-border-color);
      font-size: 1rem;
      font-weight: variables.$font-weight-normal;
      padding: .5rem .75rem !important; // overwrite component style
      transition: box-shadow .25s, border-color .25s;

      &--error,
      &:invalid {
        border-color: var(--color-error) !important;
      }

      &:hover {
        border-color: var(--form-border-color-hover) !important;
      }

      &:active,
      &:focus {
        border-color: var(--form-border-color-active) !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }

    label {
      display: none;
    }
  }

  &__select,
  &__multi-select {
    flex: 0 0 80%;
    position: relative;
    width: 100%;

    @include mixins.mq(variables.$mq-phone) {
      flex: 0 0 100%;
    }

    #{$root}--multi-row & {
      flex: 0 0 100%;
    }
  }

  &--invalid,
  &--required {
    .multiselect__tags {
      border-color: var(--color-error);
    }
  }

  &__hidden-input {
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    visibility: hidden;
    z-index: -1;
  }

  &__form {
    width: 100%;
  }

  &__error-message {
    box-sizing: border-box;
    color: var(--color-error);
    flex: 0 0 80%;
    margin-left: 20%;
    padding-left: .75rem;
    width: 100%;

    @include mixins.mq(variables.$mq-phone) {
      flex: 0 0 100%;
    }

    #{$root}--boolean & {
      order: 2;
    }

    #{$root}--multi-row & {
      flex: 0 0 100%;
      margin-left: 0;
    }
  }

  &__remove-row {
    justify-content: flex-end;
  }

  &__hint {
    color: var(--color-gray-600);
    flex: 0 0 calc(80% - .5rem - 4px);
    font-size: .9rem;
    font-style: italic;
    font-weight: variables.$font-weight-light;
    margin-left: auto;
    order: 10;
    padding-top: .25rem;

    #{$root}--multi-row & {
      flex: 0 0 100%;
    }

    #{$root}--boolean & {
      flex: 0 0 calc(100% - 2.5rem);
    }

    @include mixins.mq(variables.$mq-phone) {
      flex: 0 0 100%;
    }
  }

  p {
    margin: 0 0 .5rem;
    padding: 0 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ck-editor {
    flex: 0 0 80%;
    width: 100%;
  }

  .multiselect__content-wrapper {
    .multiselect__option--highlight:after {
      content: "";
    }
  }

  &--disabled {
    .multiselect--disabled {
      background: var(--color-gray-100);

      .multiselect__select, .multiselect__tags {
        background: var(--color-gray-100);

        .multiselect__single {
          background: var(--color-gray-100);
        }
      }
    }

    .form-element__boolean-input {
      background: var(--color-gray-100);

      svg {
        opacity: 0.6;
      }
    }
  }

  .vue-phone-number-input {
    .country-selector__input {
      padding-top: 0px !important;
    }

    .country-selector__country-flag {
      top: 14px !important;
    }
  }

  input[type="text"][disabled],
  input[type="email"][disabled],
  input[type="number"][disabled],
  textarea[disabled] {
    background-color: var(--color-gray-100);
    opacity: 0.6;
  }
}
</style>
