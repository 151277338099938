import { ConcentrationsData, RunConcentration } from './interface/RunConcentration';
import { SubTree, Tree } from './interface/Menu';
import { Course } from './interface/Course';
import { RunUnit } from './interface/RunUnit';

export default class Concentrations implements ConcentrationsData {
  professional: RunConcentration[] = [];
  bachelor: RunConcentration[] = [];
  language: RunConcentration[] = [];
  orientation: Course[] = [];
  error: any = null;

  constructor (data: ConcentrationsData|undefined) {
    this.professional = data?.professional ?? [];
    this.bachelor = data?.bachelor ?? [];
    this.language = data?.language ?? [];
    this.orientation = data?.orientation ?? [];
    this.error = data?.error;
  }

  hasMoreThanOneConcentration (): boolean {
    let count = 0;
    count += this.professional.length;
    count += this.bachelor.length;
    count += this.language.length;
    return count > 1;
  }

  dataAsArray (): Record<string, RunConcentration[]> {
    return {
      professional: this.professional,
      bachelor: this.bachelor,
      language: this.language,
    };
  }

  dataAsFlatArray (): RunConcentration[] {
    let array: RunConcentration[] = [];
    array = array.concat(this.bachelor);
    array = array.concat(this.professional);
    array = array.concat(this.language);
    return array;
  }

  units (courseCode :string): RunUnit[] {
    let units: RunUnit[] = [];
    const concentrations: RunConcentration[] = this.dataAsFlatArray();
    concentrations.forEach((concentration) => {
      concentration.courses.forEach((course) => {
        if (course.code === courseCode) {
          units = course.units;
        }
      });
    });
    return units;
  }

  menu (): Record<'bachelor' | 'professional' | 'orientation' | string, Tree> {
    const bachelorTree: Tree = {
      children: this.convertConcentrationToTree(this.bachelor),
      modifier: 'bachelor',
      translatable: 'submenu.bachelor',
    };
    const pccTree: Tree = {
      children: this.convertConcentrationToTree(this.professional),
      modifier: 'professional',
      translatable: 'submenu.professional',
    };
    const orientationTree: Tree = {
      children: this.convertCourseToTree(this.orientation, 'orientation'),
      modifier: 'orientation',
      translatable: 'submenu.orientation',
    };

    return {
      bachelor: bachelorTree,
      professional: pccTree,
      orientation: orientationTree,
    };
  }

  convertConcentrationToTree (values: RunConcentration[]): SubTree[] {
    const subTree: SubTree[] = [];

    values.forEach((concentration) => {
      subTree.push({
        children: [],
        code: concentration.code,
        hasChildren: false,
        id: concentration.id,
        label: concentration.title,
        type: concentration.programmeType,
        startDate: concentration.startDate ? new Date(concentration.startDate) : null,
        endDate: concentration.endDate ? new Date(concentration.endDate) : null,
      });
    });

    return subTree.sort((node1, node2) => {
      const date1 = node1.startDate;
      const date2 = node2.startDate;

      if (date1 && date2) {
        return date2.getTime() - date1.getTime();
      }

      if (date1) {
        return 1;
      }

      return -1;
    });
  }

  convertCourseToTree (values: Course[], type: string): SubTree<null>[] {
    const subTree: SubTree<null>[] = [];

    this.orientation.forEach((course) => {
      subTree.push({
        children: [],
        code: course.code,
        hasChildren: false,
        id: course.id,
        label: course.title,
        type,
        startDate: null,
        endDate: null,
      });
    });

    return subTree.sort((node1, node2) => (node1.code < node2.code ? -1 : 1));
  }
}
