/* eslint-disable import/prefer-default-export */
import countries from 'i18n-iso-countries/langs/en.json';
import FormElementOptions, { SelectOption } from '../helper/FormElementOptions';
import LANGUAGES_LIST from '../helper/LANGUAGES_LIST';

const COUNTRIES_LIST: SelectOption[] = [];

Object.keys(countries.countries).forEach((countryCode) => {
  COUNTRIES_LIST.push({
    id: countryCode,
    label: `countries.${countryCode}`,
  });
});

const DEVICE_OPTIONS: SelectOption[] = [
  { id: null, label: 'userManagement.deviceType.none' },
  { id: 'tablet', label: 'userManagement.deviceType.tablet' },
  { id: 'tablet_keyboard', label: 'userManagement.deviceType.tablet_keyboard' },
  { id: 'laptop', label: 'userManagement.deviceType.laptop' },
];

const ENGLISH_COMMUNICATION_LEVELS: SelectOption[] = [
  { id: '0', label: 'formElement.eclOptions.0' },
  { id: '1', label: 'formElement.eclOptions.1' },
  { id: '2', label: 'formElement.eclOptions.2' },
  { id: '3', label: 'formElement.eclOptions.3' },
  { id: '4', label: 'formElement.eclOptions.4' },
  { id: '5', label: 'formElement.eclOptions.5' },
  { id: '6', label: 'formElement.eclOptions.6' },
  { id: '7', label: 'formElement.eclOptions.7' },
];

export const RESIDENCY_STATUS: SelectOption[] = [
  { id: 'hc', label: 'studentManagement.residencyStatusValues.hc' },
  { id: 'idp', label: 'studentManagement.residencyStatusValues.idp' },
  { id: 'indigenous', label: 'studentManagement.residencyStatusValues.indigenous' },
  { id: 'refugee', label: 'studentManagement.residencyStatusValues.refugee' },
  { id: 'returnees', label: 'studentManagement.residencyStatusValues.returnees' },
  { id: 'rural', label: 'studentManagement.residencyStatusValues.rural' },
  { id: 'urban', label: 'studentManagement.residencyStatusValues.urban' },
  { id: 'other', label: 'studentManagement.residencyStatusValues.other' },
];

export const SCHOOL_LEVELS: SelectOption[] = [
  { id: 'grade_school', label: 'studentManagement.schoolingLevel.grade_school' },
  { id: 'middle_school', label: 'studentManagement.schoolingLevel.middle_school' },
  { id: 'high_school', label: 'studentManagement.schoolingLevel.high_school' },
];

export const OTHER_IDENTIFICATIONS: SelectOption[] = [
  { id: null, label: 'studentManagement.otherIdentificationOptions.none' },
  { id: 'passport', label: 'studentManagement.otherIdentificationOptions.passport' },
  { id: 'local_identification', label: 'studentManagement.otherIdentificationOptions.localId' },
];

export const GENDER_OPTIONS = [
  { id: 'male', label: 'studentManagement.male' },
  { id: 'female', label: 'studentManagement.female' },
  { id: 'other', label: 'studentManagement.other' },
];

export const REFERRAL_OPTIONS = [
  { id: 'social', label: 'formElement.referral-options.social' },
  { id: 'staff', label: 'formElement.referral-options.staff' },
  { id: 'poster', label: 'formElement.referral-options.poster' },
  { id: 'ad', label: 'formElement.referral-options.ad' },
  { id: 'gel', label: 'formElement.referral-options.gel' },
  { id: 'professional', label: 'formElement.referral-options.professional' },
  { id: 'graduate', label: 'formElement.referral-options.graduate' },
  { id: 'friend', label: 'formElement.referral-options.friend' },
  { id: 'other', label: 'formElement.referral-options.other' },
];

const YEAR_SELECTION: SelectOption[] = [];
const currentDate = new Date();
let currentYear = currentDate.getFullYear();
const oldestYear = currentYear - 100;

while (oldestYear <= currentYear) {
  YEAR_SELECTION.push({ id: currentYear.toString(), label: currentYear.toString() });
  currentYear -= 1;
}

export const COUNTRY_LIST = COUNTRIES_LIST;

export const FIELD_OPTIONS = {
  attendedVocationalSchool: new FormElementOptions('attended-vocational-school', 'boolean'),
  birthDate: new FormElementOptions('birth-date', 'date', true),
  certificatePhoto: new FormElementOptions('certificate-photo', 'file'),
  countryOfBirth: new FormElementOptions('country-of-birth', 'select', false, COUNTRIES_LIST),
  placeOfBirth: new FormElementOptions('place-of-birth', 'text', false),
  countryOfOrigin: new FormElementOptions('country-of-origin', 'select', false, COUNTRIES_LIST),
  countryOfSchool: new FormElementOptions('country-of-school', 'select', false, COUNTRIES_LIST),
  currentCity: new FormElementOptions('city', 'text', false),
  deviceId: new FormElementOptions('rented-device-id', 'text'),
  deviceType: new FormElementOptions('rented-device-type', 'select', false, DEVICE_OPTIONS),
  displayName: new FormElementOptions('display-name', 'text', true),
  ecl: new FormElementOptions('ecl', 'select', false, ENGLISH_COMMUNICATION_LEVELS),
  email: new FormElementOptions('email', 'email', true),
  excludeFromReporting: new FormElementOptions('exclude-from-reporting', 'boolean'),
  firstName: new FormElementOptions('first-name', 'text', true),
  gender: new FormElementOptions('gender', 'select', true, GENDER_OPTIONS),
  highestLevelOfSchool: new FormElementOptions('highest-school-level', 'select', false, SCHOOL_LEVELS),
  lastGraduationYear: new FormElementOptions('last-graduation-year', 'select', false, YEAR_SELECTION, false),
  lastGraduationDate: new FormElementOptions('last-graduation-date', 'date', false),
  lastName: new FormElementOptions('last-name', 'text', true),
  leadFacilitatorAble: new FormElementOptions('lead-facilitator-able', 'boolean'),
  middleName: new FormElementOptions('middle-name', 'text'),
  nationality: new FormElementOptions('nationality', 'select', false, COUNTRIES_LIST),
  nativeLanguage: new FormElementOptions('native-language', 'select', false, LANGUAGES_LIST, false),
  otherCommunicationChannels: new FormElementOptions('other-communication-channels', 'textarea'),
  otherIdentificationType: new FormElementOptions('other-identification-type', 'select', false, OTHER_IDENTIFICATIONS),
  otherIdentificationValue: new FormElementOptions('other-identification-value', 'text'),
  phone: new FormElementOptions('phone', 'text', true),
  profilePhoto: new FormElementOptions('profile-photo', 'file'),
  residencyStatus: new FormElementOptions('residency-status', 'select', false, RESIDENCY_STATUS),
  unhcrNumber: new FormElementOptions('unhcr-number', 'text'),
  userName: new FormElementOptions('user-name', 'text'),
  vocationalSchoolCompleted: new FormElementOptions('vocational-school-completion-year', 'select', false, YEAR_SELECTION, false),
  vocationalSchoolCountry: new FormElementOptions('vocational-school-country', 'select', false, COUNTRIES_LIST),
  vocationalSchoolName: new FormElementOptions('vocational-school-name', 'text', false),
  vocationalSchoolSubjects: new FormElementOptions('vocational-school-subjects', 'text', false),
  vocationalSchoolWithDegree: new FormElementOptions('vocational-school-completed', 'boolean'),
  vocationalSchoolYears: new FormElementOptions('vocational-school-years', 'number', false),
  enabled: new FormElementOptions('enabled', 'boolean'),
  affiliation: new FormElementOptions('affiliation', 'text', true),
};
