import { useRouter } from 'vue2-helpers/vue-router';
import useModuleStore from '@base/store/moduleStore';
import { IS_ROLE_AND_RIGHT } from '@base/store/JwlGlobals';
import { UserAuthorisation } from '../store/UserAuthorisation';

const UserAuthorisationHelper = {

  install (Vue: any) {
    Vue.prototype.$userAuthorisation = UserAuthorisation;
    Vue.prototype.$isAuthorised = (rightId: string, redirect = true) => {
      const { currentUser } = useModuleStore();
      const router = useRouter();

      if (!IS_ROLE_AND_RIGHT) {
        return true;
      }

      if (currentUser?.applicationRights.includes(rightId)) {
//        console.log(`AuthorisationLogger: rightId=${rightId} is in rights of ${currentUser.id}`);
        return true;
      }
      if (currentUser) {
        if (redirect) {
          router.push({ name: 'notAuthorised' }).catch();
          //        console.log(`UserAuthorisationHelper.ts: rightId=${rightId} is NOT in rights of ${currentUser?.id}`);
        } else {
//            console.log(`UserAuthorisationHelper.ts: No redirect but rightId=${rightId} is NOT in rights of ${currentUser?.id}`);
        }
      }
      return false;
    };

    Vue.prototype.$isResponsible = (taskResponsibility: string|undefined) => {
      if (!taskResponsibility) {
        return false;
      }
      const { currentUser } = useModuleStore();
      const rights = UserAuthorisation;
      const allowedOnlineTasks = currentUser?.applicationRights?.includes(rights.RIGHT__COURSE_TASK_ONLINE__DISPLAY);
      const allowedOnsiteTasks = currentUser?.applicationRights?.includes(rights.RIGHT__COURSE_TASK_ONSITE__DISPLAY);
      const isOnlineAndResponsible = allowedOnlineTasks && ['online_facilitator', 'online-onsite_facilitator'].includes(taskResponsibility);
      const isOnsiteAndResponsible = allowedOnsiteTasks && ['onsite_facilitator', 'onsite-online_facilitator'].includes(taskResponsibility);
      return (isOnlineAndResponsible || isOnsiteAndResponsible) && !currentUser?.applicationRights?.includes(rights.RIGHT__SUBMISSION__CREATE);
    };
  },
};

export default UserAuthorisationHelper;
