<template>
  <div class="jwl-profile" v-if="hasUserData">
    <div class="jwl-profile__menu-bar" @click="toggle">
      <icon-angle-down class="jwl-profile__user-toggle" />
      <span class="jwl-profile__user-name" v-text="currentUser.firstName" />
      <jwl-profile-image class="jwl-profile__user-icon" :image-url="currentUser.profilePhoto" />
    </div>

    <div class="jwl-profile__flyout" :class="flyoutOpenClass">
      <jwl-profile-image class="jwl-profile__flyout-user-icon" :image-url="currentUser.profilePhoto" />
      <div class="jwl-profile__flyout-user-name">
        {{currentUser.firstName}} {{currentUser.lastName}}
      </div>

      <div class="jwl-profile__flyout-user-meta">
        ID: {{currentUser.id}}
      </div>

      <div class="jwl-profile__flyout-action">
        <div class="jwl-profile__flyout-log-out" @click="viewProfile">
          {{$root.$t('common.toProfile')}}
        </div>

        <div class="jwl-profile__flyout-log-out" @click="logout">
          {{$root.$t('general.logOut')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapStores } from 'pinia';
import useModuleStore from '@base/store/moduleStore';
import jwlProfileImage from './jwlProfileImage.vue';
import { IMPERSONATE_KEY } from '../store/JwlApi';

const IconAngleDown = () => import('../assets/fa-icons/solid/angle-down.svg');

export default {
  name: 'jwlProfile',
  components: {
    IconAngleDown,
    jwlProfileImage,
  },
  data () {
    return {
      open: false,
    };
  },
  methods: {
    toggle () {
      this.open = !this.open;
    },
    logout () {
      this.moduleStore.logOutUser();
      this.$router.push({ name: 'login' });
    },
    viewProfile () {
      this.$root.$router.push({ name: 'profile' });
    },
  },
  computed: {
    flyoutOpenClass () {
      return this.open ? 'jwl-profile__flyout--open' : '';
    },
    ...mapStores(useModuleStore),
    ...mapState(useModuleStore, [
      'currentUser',
      'hasUserData',
    ]),
  },
  watch: {
    $route () {
      this.open = false;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/jwlProfile";
</style>
